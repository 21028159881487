import { JSX, splitProps } from "solid-js";
import { A } from "@solidjs/router";

export type LinkProps = {
  activeClass?: string;
  end?: boolean;
} & JSX.AnchorHTMLAttributes<HTMLAnchorElement>;

export function Link(props: LinkProps) {
  const [local, otherProps] = splitProps(props, [
    "activeClass",
    "class",
    "href",
  ]);

  return (
    <A
      href={props.href ?? "#"}
      activeClass={local.activeClass}
      class={local.class}
      {...otherProps}
    >
      {props.children}
    </A>
  );
}
